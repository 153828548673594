import getDate from "@/functions/getDate"

const getExtensionMessageConfigs = async (configs) => {
	Date.prototype.addHours = function(h) {
		this.setTime(this.getTime() + h * 60 * 60 * 1000)
		return this
	}

	let showExtensionMessage = false
	let showPostExtensionMessage = false
	let showOffSeasonMessage = false
	let showOffPreseasonMessage = false
	let showPostStartEFileMessage = false
	let showExtensionSlide = false

	const currentDate = getDate()
	const startExtensionDate = new Date(configs.startExtension)
	const startEfile = new Date(configs.startEFile)
	const aprilFirst = new Date(configs.currentTaxYear + 1 + "/04/01")
	const aprilTaxDeadlinePlusFourHours = new Date(
		configs.aprilTaxDeadline
	).addHours(4)
	const aprilTaxDeadlinePlusFiveDays = new Date(
		configs.aprilTaxDeadline
	).addHours(96)

	if (
		currentDate >= startExtensionDate &&
		currentDate <= aprilTaxDeadlinePlusFourHours
	) {
		showExtensionMessage = true
	}
	else if (
		currentDate >= aprilTaxDeadlinePlusFourHours &&
		currentDate <= aprilTaxDeadlinePlusFiveDays
	) {
		showPostExtensionMessage = true
	}
	else if (currentDate >= startEfile && currentDate < startExtensionDate) {
		showPostStartEFileMessage = true
	}
	else if (currentDate < startExtensionDate) {
		showOffPreseasonMessage = true
	}
	else {
		showOffSeasonMessage = true
	}

	if (
		currentDate >= aprilFirst &&
		currentDate <= aprilTaxDeadlinePlusFourHours
	) {
		showExtensionSlide = true
	}

	configs.showExtensionMessage = showExtensionMessage
	configs.showPostExtensionMessage = showPostExtensionMessage
	configs.showOffSeasonMessage = showOffSeasonMessage
	configs.showOffPreseasonMessage = showOffPreseasonMessage
	configs.showPostStartEFileMessage = showPostStartEFileMessage
	configs.showExtensionSlide = showExtensionSlide

	return configs
}

export default getExtensionMessageConfigs
