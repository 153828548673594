<template>
  <Navbar />
  <MobileMenu />
  <router-view />
  <Footer />
</template>

<script setup>
import "./scss/_global.scss"
import {defineAsyncComponent, toRaw, watch} from "vue"
import {useRoute} from "vue-router"
import Navbar from "@/components/Navbar"
import MobileMenu from "@/components/MobileMenu"
import LoadingBlank from "@/components/LoadingBlank"
import menuToggle from "@/functions/menuHandler"
import getDate from "@/functions/getDate"
import getConfigs from "@/functions/getConfigs"
import getPrices from "@/functions/getPrices"
import {store} from "@/functions/store"

const route = useRoute();
const { closeMenu } = menuToggle();

getConfigs();
getPrices();

watch(route, () => {
  closeMenu();
});

const Footer = defineAsyncComponent({
  // the loader function
  loader: () =>
    new Promise((resolve) => {
      setTimeout(() => {
        resolve(import("@/components/Footer"));
      }, 100);
    }),

  // A component to use while the async component is loading
  loadingComponent: LoadingBlank,
  // Delay before showing the loading component. Default: 200ms.
  delay: 500,
});

// for time travel testing logs
const isTimeTravelTesting = process.env.VUE_APP_TIME_TRAVEL;
if (isTimeTravelTesting === "true" && location.href.includes("date=")) {
  const showTestConsoleLogs = async () => {
    console.log("%cTime Travel!", "font-weight:bold;font-size:2rem;");
    console.log(getDate());
    console.log("configs", toRaw(store.configs));
    console.log("prices", toRaw(store.prices));
  };
  setTimeout(showTestConsoleLogs, 1000);
}
</script>

<style lang="scss">
.router-link-active {
  &.mobile-menu-link {
    box-shadow: none;
  }
}
</style>
