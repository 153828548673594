import "bootstrap/dist/css/bootstrap.min.css"
import "bootstrap"
import {createApp} from "vue"
import VueMatomo from "vue-matomo"
import App from "./App.vue"
import router from "./router"
import matomo from "@/functions/matomo"

createApp(App)
.use(router)
.use(VueMatomo, {
	host: "https://analytics.taxhawk.com",
	siteId: 2
})
.mount("#app")

matomo()